import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import Count from "../../../../../components/count"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/alliterationen-und-assonanzen" />
      }
    >
      <Seo title="Silben klopfen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Silben klopfen
            </Heading>
          </Stack>
          <Paragraph>
            Die 1. Strophe hat <Count>42</Count> Silben
            <br /> Die 2. Strophe hat <Count>41</Count> Silben
          </Paragraph>
          <Paragraph>
            Auf den zweiten Blick zeigt sich, dass die beiden Strophen beinahe
            exakt gleichlang sind. Der 2. Strophe fehlt lediglich 1 Silbe. Hat
            Hölderlin hier geschlampt?
          </Paragraph>
          <Paragraph>
            Vielleicht hat er die letzte Silbe auch ganz bewusst weggelassen?
            Immerhin wird in der 2. Strophe ein Verlust der Sprache beklagt.
            Dazu passt, dass die letzte Silbe unausgesprochen bleibt und damit
            eine Leerstelle erzeugt, in der lediglich das Klirren der Fahnen
            nachklingt.
          </Paragraph>
          <Paragraph>
            Das führt uns zum nächsten Schritt, in dem wir uns mit dem Klang des
            Gedichts beschäftigen.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
